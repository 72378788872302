import { scrollIntoViewHorizontally } from '../../utils';
/**
 * Checks if a supplied child node is outside the left bound of a supplied
 * parent node. If so, true is returned. If not, false is returned. It is intended
 * to be used as an input to the array filter function.
 *
 * @example
 * childHiddenToLeftOfParent({...attributes of parent DOM element})({...attributes of child DOM element})
 * // returns boolean
 */
const childHiddenToLeftOfParent = (parent: HTMLElement) => (child: Element) =>
    Math.ceil(child.getBoundingClientRect().left - parent.getBoundingClientRect().left) < 0;

/**
 * Checks if a supplied child node is outside the right bound of a supplied
 * parent node. If so, true is returned. If not, false is returned. It is intended
 * to be used as an input to the array filter function.
 *
 * @example
 * childHiddenToRightOfParent({...attributes of parent DOM element})({...attributes of child DOM element})
 * // returns boolean
 */
const childHiddenToRightOfParent = (parent: HTMLElement) => (child: Element) =>
    Math.floor(child.getBoundingClientRect().right - parent.getBoundingClientRect().right) > 0;

/**
 * Returns an array of children elements from a parent element.
 *
 * @example
 * childrenOfParent({...attributes of parent DOM element})
 * // returns [{child DOM element}, {child DOM element}, ...]
 */
const childrenOfParent = (element: HTMLElement) => [...element.children];

/**
 * Checks if the children of a parent node are horizontally outside of the parent
 * node's bounds. If there are children nodes outside of the right side of
 * the parent's bounds, then the closest child node will be scrolled into
 * view.
 *
 * @example
 * scrollToNextElement({...attributes of DOM element})
 * // returns nothing
 */
export const scrollToNextElement = (element: HTMLElement) => {
    const children = childrenOfParent(element);

    const hiddenChildren = children.filter(childHiddenToRightOfParent(element));

    if (hiddenChildren && hiddenChildren.length) {
        scrollIntoViewHorizontally(element, hiddenChildren[0] as HTMLElement);
    }
};

/**
 * Checks if the children of a parent node are horizontally outside of the parent
 * node's bounds. If there are children nodes outside of the left side of
 * the parent's bounds, then the closest child node will be scrolled into
 * view.
 *
 * @example
 * scrollToPreviousElement({...attributes of DOM element})
 * // returns nothing
 */
export const scrollToPreviousElement = (element: HTMLElement) => {
    const children = childrenOfParent(element).reverse();

    const hiddenChildren = children.filter(childHiddenToLeftOfParent(element));

    if (hiddenChildren && hiddenChildren.length) {
        scrollIntoViewHorizontally(element, hiddenChildren[0] as HTMLElement);
    }
};
