import React from 'react';
import { Box } from '../Box';
import Typography from '../Typography';
import { ArrowDropDownIcon, InfoIcon } from '../Icons';
import { Tooltip } from '../Tooltip';
import { buildLinearGradient } from '../../utils/buildGradient';
import { BarGradientLabel } from './BarGradientLabel';

interface BarGradientProps {
    value?: number;
    valueText?: string;
    min?: number;
    minLabel?: string;
    minTooltip?: string;
    max?: number;
    maxLabel?: string;
    maxTooltip?: string;
    colors?: string[];
    gradientString?: string;
}

const getPercentageWidth = (min: number, max: number, value: number) => {
    let percentageWidth: number;
    const range = max - min;
    if (value <= min) {
        /**
         * setting width to -3% if value is at the max of the range to account for spacing
         * */
        percentageWidth = -3;
    } else if (value >= range) {
        /**
         * setting width to 96% if value is at the max of the range to account for spacing
         * */
        percentageWidth = 96;
    } else {
        percentageWidth = (value / range) * 100;
    }
    return percentageWidth;
};

const BarGradient = ({
    value,
    valueText,
    min,
    minLabel,
    minTooltip,
    max,
    maxLabel,
    maxTooltip,
    colors,
    gradientString
}: BarGradientProps) => {
    const hasData = value !== undefined && min !== undefined && max !== undefined;
    const percentageWidth = hasData ? getPercentageWidth(min, max, value) : undefined;

    const colorGradient = gradientString
        ? gradientString
        : colors
          ? buildLinearGradient(colors)
          : buildLinearGradient();

    return (
        <Box width="100%">
            <BarGradientLabel label={valueText || `${value}`} />
            {/* indicator arrow */}
            {hasData && (
                <ArrowDropDownIcon
                    sx={{
                        fontSize: '32px',
                        marginLeft: `${percentageWidth}%`,
                        marginBottom: '-14px'
                    }}
                />
            )}
            {/* color gradient */}
            <Box
                sx={{
                    height: '20px',
                    width: '100%',
                    marginBottom: '4px',
                    background: colorGradient,
                    marginTop: `${hasData ? 0 : '24px'}`
                }}
            />
            {/* low/high labels & tooltips */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="body2">{minLabel || min}</Typography>
                    {minTooltip && (
                        <Tooltip title={minTooltip}>
                            <InfoIcon
                                sx={{
                                    fontSize: '14px',
                                    marginLeft: '2px'
                                }}
                            />
                        </Tooltip>
                    )}
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="body2">{maxLabel || max}</Typography>
                    {maxTooltip && (
                        <Tooltip title={maxTooltip}>
                            <InfoIcon
                                sx={{
                                    fontSize: '14px',
                                    marginLeft: '2px'
                                }}
                            />
                        </Tooltip>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default BarGradient;
