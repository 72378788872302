import { getLinearColorStops, warm14DispersedAnchors } from '@methanesat/colors';
import { roundUsingSigFigs } from '../../../utils/numbers';
import { useTranslate } from '../../../hooks';
import { AreaEmissionsProducts, MethaneLayerIds, Platforms } from '../../../types';
import { CONCENTRATION_COLOR_STOPS_LOG, EMISSIONS_RANGES } from '../../../consts';

export interface ColorStopLabel {
    /** Percentage for positioning. */
    percent: number;
    /** Label to display next to color stop. */
    label: string;
    /** Key for React element uniqueness. */
    key: string;
}

/** Generates an array of `ColorStopLabel` objects, with evenly spaced percentages assigned to the scale labels.
 * Percentages are calculated based on the index of each label relative to the total number of labels.
 */
const generateEvenlySpacedLabels = (scaleValues: number[]): ColorStopLabel[] => {
    const stopCount = scaleValues.length;
    return scaleValues.map((val, index) => {
        const label = roundUsingSigFigs(val);
        return {
            percent: parseFloat(((index / (stopCount - 1)) * 100).toFixed(2)),
            label,
            key: label
        };
    });
};

/** Custom kg/hr values for the target color scale in the mini legend. These are assigned manually
 * since the spacing between them may be irregular. They should be unique and sorted in ascending order.
 */
const TARGET_SCALE_VALUES = {
    [Platforms.MAIR]: [10_000, 50_000, 100_000, 140_000],
    [Platforms.MSAT]: [5_000, 140_000, 270_000, 400_000]
};

/** Mini-legend target scale labels */
const targetScaleLabels = {
    [Platforms.MAIR]: generateEvenlySpacedLabels(TARGET_SCALE_VALUES[Platforms.MAIR]),
    [Platforms.MSAT]: generateEvenlySpacedLabels(TARGET_SCALE_VALUES[Platforms.MSAT])
};

/** Labels for the mini legend dispersed source scale. Since the color bins used for this scale
 * are non-linear and irregular, these are created manually.
 * See https://methanesat.atlassian.net/browse/DP-4151
 * for the corresponding colorbar anchor position for specific emission values.
 */
const msatDispersedScaleLabels = [
    {
        percent: 100,
        label: '100',
        key: '100'
    },
    { percent: 88, label: '50', key: '50' },
    { percent: 75, label: '15', key: '15' },
    { percent: 50, label: '10', key: '10' },
    { percent: 29, label: '5', key: '5' },
    {
        percent: 0,
        label: '1',
        key: '1'
    }
];

/**
 * Creates labels for color stops from a min/max, segmenting linearly.
 */
export const getColorStopLabels = (
    min: number,
    max: number,
    colorStopCount = 4,
    ascending = false
): ColorStopLabel[] => {
    const stops = getLinearColorStops(colorStopCount, min, max);

    return stops.map((stopVal, index) => {
        const percentageStep = parseFloat(((index / (colorStopCount - 1)) * 100).toFixed(2));
        const label = Math.floor(stopVal).toLocaleString();

        return {
            percent: ascending ? 100 - percentageStep : percentageStep,
            label,
            key: label
        };
    });
};

/**
 * Adds to target labels "or more/less" text (using locales), tooltips, etc.  Intended to be used after running
 * getColorStopLabels().
 */
export const useAugmentLabels = (labels: ColorStopLabel[], unitsKey: string, tooltip: string) => {
    const t = useTranslate();
    return labels.map((labelObj) => {
        const { percent, label, key } = labelObj;

        // Highest value.
        if (percent === 100) {
            return {
                key,
                label: t('emissionsMapPage.miniLegend.generalEmissions.orMore', {
                    value: label,
                    unit: t(`emissionsMapPage.units.${unitsKey}`)
                }),
                percent,
                tooltip: t(tooltip)
            };
        }

        // Lowest value.
        if (percent === 0) {
            return {
                key,
                label: t('emissionsMapPage.miniLegend.generalEmissions.orLess', {
                    value: label
                }),
                percent
            };
        }

        // No-op passthrough.
        return labelObj;
    });
};

/** Generates color stop labels specific to a platform and type (target or dispersed sources). */
export const generateColorStopLabels = (platform: Platforms, layerId: MethaneLayerIds) => {
    let labels: ColorStopLabel[] = [];

    switch (layerId) {
        case MethaneLayerIds.areaEmissionRaster: {
            if (platform === Platforms.MAIR) {
                const { MAX } = EMISSIONS_RANGES[platform][MethaneLayerIds.areaEmissionRaster];
                labels = getColorStopLabels(1, MAX);
            } else {
                labels = msatDispersedScaleLabels;
            }
            break;
        }
        case MethaneLayerIds.targets: {
            return targetScaleLabels[platform];
        }
    }
    return labels;
};

/** Returns an array of {percent, color} objects to be passed to the mini legend thermometer for L3 and L4 data.
 *
 * **L3 data**: Maps colors based on logarithmic stops, globally.
 * **MSAT L4 data**: Maps colors using predefined anchor positions.
 * **MAIR L4 data**: Evenly distributes colors across the palette.
 */
export const getEmissionsThermometerColors = (
    platform: Platforms,
    product: AreaEmissionsProducts,
    colors: string[]
) => {
    if (product === AreaEmissionsProducts.l3) {
        const CONCENTRATION_COLOR_STOPS_LOG_REVERSED = CONCENTRATION_COLOR_STOPS_LOG.toReversed();
        return colors.map((color, index) => {
            const max = CONCENTRATION_COLOR_STOPS_LOG_REVERSED[CONCENTRATION_COLOR_STOPS_LOG.length - 1];
            const min = CONCENTRATION_COLOR_STOPS_LOG_REVERSED[0];
            const total = max - min;

            return {
                percent: ((CONCENTRATION_COLOR_STOPS_LOG_REVERSED[index] - min) / total) * 100,
                color
            };
        });
    } else {
        if (platform === Platforms.MSAT) {
            return colors.map((color, index) => ({
                percent: warm14DispersedAnchors[index],
                color: color
            }));
        } else {
            return colors.map((color, index, arr) => {
                return {
                    percent: (index / arr.length) * 100,
                    color
                };
            });
        }
    }
};
