import React, { useRef, useEffect } from 'react';
import { chroma } from '@methanesat/maps';
import { Box, InfoIcon, Tooltip, Typography } from '@methanesat/ui-components';
import type { ReactElement } from 'react';

export interface ColorThermometerProps {
    accentuateLowestValue?: boolean;
    colors: { percent: number; color: string }[];
    height?: string;
    labels?: { percent: number; label: string; tooltip?: string }[];
}

const ColorThermometer = ({
    accentuateLowestValue = false,
    colors,
    labels,
    height = '150px'
}: ColorThermometerProps): ReactElement => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            if (ctx) {
                canvas.height = parseFloat(height);
                canvas.width = 25;

                const colorScale = chroma
                    .scale(colors.map((c) => c.color))
                    .mode('lch')
                    .domain(colors.map((c) => (c.percent / 100) * parseFloat(height)));

                // Draw the gradient
                for (let i = 0; i < canvas.height; i++) {
                    ctx.fillStyle = colorScale(i).css();
                    ctx.fillRect(0, canvas.height - i, canvas.width, 1);
                }
            }
        }
    }, [colors, height]);

    const labelHeightPx = 16;

    return (
        <Box paddingTop={1} paddingBottom={1}>
            <Box display="flex" alignContent="center">
                <canvas
                    ref={canvasRef}
                    aria-label="Color legend"
                    role="presentation"
                    style={{
                        flexBasis: '25px'
                    }}
                />
                <Box display="flex" position="relative" fontSize="12px" flex="1">
                    {labels?.map(({ percent, label, tooltip }) => {
                        return (
                            <Box
                                top={`calc(${100 - percent}% - ${labelHeightPx / 2}px)`}
                                paddingLeft="1rem"
                                width="100%"
                                position="absolute"
                                key={percent}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    /** Tick mark */
                                    '&::before': {
                                        content: '"—"',
                                        position: 'absolute',
                                        left: '-2px'
                                    }
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    fontSize="0.7rem"
                                    maxHeight="0.7rem"
                                    lineHeight="1"
                                    sx={{
                                        // styles to force a hanging indent that will leave enough
                                        // space for any numbers below the wrapped label
                                        textIndent: '-3em',
                                        marginLeft: '3em'
                                    }}
                                >
                                    {label}
                                </Typography>
                                {tooltip && (
                                    <Tooltip
                                        title={tooltip}
                                        enterTouchDelay={0}
                                        leaveTouchDelay={3000}
                                        sx={{
                                            marginLeft: 0.5,
                                            fontSize: 20
                                        }}
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                )}
                            </Box>
                        );
                    })}
                </Box>
            </Box>
            {/* Slightly heightens the lowest value for visibility without disturbing the color/label alignment (see DP-4029). */}
            {!!accentuateLowestValue && (
                <Box
                    width="25px"
                    sx={{
                        color: 'white',
                        background: colors[0].color,
                        height: '10px'
                    }}
                />
            )}
        </Box>
    );
};

export default ColorThermometer;
