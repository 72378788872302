import React from 'react';
import { SxProps, Theme } from '@mui/material';

import { useTheme } from '../../hooks';
import { CalendarMonthIcon } from '../Icons';
import { Grid } from '../Layout';
import { TimeSelector } from '../TimeSelector';
import Typography from '../Typography';

import { DateOption, LocaleDateVariant } from '../../types';

export interface DatePickerProps {
    'data-testid'?: string;
    onClick: (date: number) => void;
    options: DateOption[];
    promptText: string;
    value: number;
    sx?: SxProps<Theme>;
    platform?: string;
    variant?: LocaleDateVariant;
}

export const DatePicker = ({
    'data-testid': dataTestId,
    onClick,
    options,
    promptText,
    value,
    sx,
    platform,
    variant = LocaleDateVariant.shortMonthYear
}: DatePickerProps) => {
    const theme = useTheme();

    const data = options
        .map((option, index) => ({
            id: `date-option-${index}`,
            boxHeight: '35px',
            color: theme.palette.lightGray.main,
            dateKey: option.value,
            disabled: option.disabled,
            label: option.label,
            onClick: () => onClick(option.value),
            selected: option.value === value,
            value: option.value
        }))
        .reverse();
    return (
        <Grid
            container
            sx={[
                (theme) => ({
                    ...theme.mixins.mapButton,
                    '&:hover, &:focus, &:focus-within': {},
                    padding: theme.spacing(2),
                    flexWrap: 'nowrap',
                    width: 'unset'
                }),
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
            columnSpacing={1}
            flexDirection="row"
            alignItems="center"
            margin={0}
            className="msat_date-picker"
        >
            {/* icon */}
            <Grid item xs="auto">
                <CalendarMonthIcon
                    sx={{
                        fontSize: '48px'
                    }}
                />
            </Grid>
            {/* prompt */}
            <Grid item xs={3}>
                <Typography
                    sx={{
                        lineHeight: '1.25rem'
                    }}
                >
                    {promptText}
                </Typography>
            </Grid>
            {/* date selector */}
            <Grid
                item
                xs
                // This is another magic number
                sx={{ maxWidth: 'calc(100% - 160px) !important' }}
            >
                <TimeSelector
                    data-testid={dataTestId || 'date-picker'}
                    data={data}
                    centerLabel
                    platform={platform}
                    variant={variant}
                    layout="horizontal"
                />
            </Grid>
        </Grid>
    );
};
