/** Custom function to scroll an element into view, horizontally only, in respect to a container
 * element. This is used for dates within the `TimeSelector` component.
 */
export const scrollIntoViewHorizontally = (container: HTMLElement, child: HTMLElement) => {
    const containerRect = container.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();

    const computedStyle = window.getComputedStyle(child);
    const outlineWidth = parseInt(computedStyle.outlineWidth);

    // Scroll left to bring the child into view with outline consideration
    if (childRect.left < containerRect.left) {
        container.scrollLeft -= containerRect.left - (childRect.left - outlineWidth);
    }

    // Scroll right to bring the child into view with outline consideration
    else if (childRect.right > containerRect.right) {
        container.scrollLeft += childRect.right + outlineWidth - containerRect.right;
    }
};
