import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export interface DispersedSourceIconProps {
    colors: string[];
}

const DispersedSourceIcon = ({ colors, sx }: SvgIconProps & DispersedSourceIconProps) => {
    return (
        <SvgIcon sx={[...(Array.isArray(sx) ? sx : [sx])]}>
            <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="10" height="20" transform="matrix(1 0 0 -1 0 20)" fill={colors[0]} />
                <rect width="10" height="10" transform="matrix(1 0 0 -1 10 10)" fill={colors[3]} />
                <rect width="10" height="10" transform="matrix(1 0 0 -1 20 10)" fill={colors[5]} />
                <rect width="10" height="10" transform="matrix(1 0 0 -1 10 20)" fill={colors[8]} />
                <rect width="10" height="10" transform="matrix(1 0 0 -1 20 20)" fill={colors[11]} />
            </svg>
        </SvgIcon>
    );
};

export default DispersedSourceIcon;
