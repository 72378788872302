// for basins and targets
import { Grid, SxProps, Typography } from '@methanesat/ui-components';
import { ReactNode } from 'react';

export const DrawerHeader = ({
    title,
    subtitle,
    icon,
    sx
}: {
    title: string;
    subtitle?: string;
    icon?: ReactNode;
    sx?: SxProps;
}) => {
    return (
        <Grid container flexDirection="column" sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}>
            <Grid item xs>
                <Grid container flexDirection="row" alignItems="center" columnSpacing={2}>
                    {icon && (
                        <Grid item xs="auto">
                            {icon}
                        </Grid>
                    )}
                    <Grid item xs>
                        <Typography variant="h3">{title}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {subtitle && (
                <Grid item xs>
                    <Typography variant="h6" fontSize="1.2rem" paddingTop={1}>
                        {subtitle}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};
