import { ConfigType } from 'dayjs';
import { STACCalendarData, UrlFeatureTypes } from '../../../../../types';
import { analytics, formatDateForUrl, getDayEnd, isSameTime } from '../../../../../utils';
import { getFormattedDate } from '@methanesat/ui-components';
import { centroid, polygon } from '@turf/turf';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { setSelectedFeatureParams, setTargetDate } from '../../../../../reducers';
import { log } from '@methanesat/log';

export interface handleDayClickProps {
    day: STACCalendarData;
    dispatch: Dispatch<UnknownAction>;
    targetId: string;
}

/**
 * Converts STAC-formatted dates to the format needed for the TimeSelector component.
 */
export const stacDatesToTimeSelector = (
    dates: STACCalendarData[] | null,
    selectedTime: ConfigType,
    handleClick: (arg0: STACCalendarData) => void
) => {
    if (dates === null) {
        log.error('stacDatesToTimeSelector: no dates passed in.');
        return [];
    }

    return dates.map((date) => {
        return {
            color: date.color,
            selected: isSameTime(date.date, selectedTime),
            label: getFormattedDate(date.date),
            dateKey: date.id,
            boxHeight: '50px',
            onClick: () => handleClick(date),
            value: date.date.getTime()
        };
    });
};

/**
 * Handles users clicking on individual days in the target drawer's observations over time feature.
 * Sets the target date, selected feature parameters, and fires the relevant analytics event.
 */
export const handleTargetDrawerDayClick = ({ day, dispatch, targetId }: handleDayClickProps) => {
    // setting date to very end of the selected day
    // for consistency with global date, which must set to end of day
    const endOfDay = getDayEnd(day.date).getTime();

    const featurePolygon = polygon([day.coordinates]);
    const [lng, lat] = centroid(featurePolygon).geometry.coordinates;

    // update target-specific date
    dispatch(setTargetDate({ date: endOfDay, targetId }));

    // update URL
    dispatch(
        setSelectedFeatureParams({
            itemId: day.id,
            collectionId: day.collection || null,
            featureType: UrlFeatureTypes.Target,
            coordinates: [lng, lat],
            targetDate: formatDateForUrl(new Date(endOfDay)),
            targetId: targetId
        })
    );

    // fires off an analytics event to track feature usage statistics.
    analytics.clickTargetDrawerObservation({
        itemId: day.id,
        platform: day.platform,
        emissionRate: day.totalKgHr ?? NaN,
        label: `${endOfDay}`
    });
};

/**
 * Handles users clicking on individual days in the floating target observations over time feature.
 * Sets the target date and fires the relevant analytics event.
 */
export const handleFloatingSummaryDayClick = ({ day, dispatch, targetId }: handleDayClickProps) => {
    // setting date to very end of the selected day
    // for consistency with global date, which must set to end of day
    const endOfDay = getDayEnd(day.date).getTime();

    // update target-specific date
    dispatch(setTargetDate({ date: endOfDay, targetId }));

    // TODO: create specific event for clicking target summary observations.
    // https://methanesat.atlassian.net/browse/DP-4637?atlOrigin=eyJpIjoiZmQzMWJjMWE2ZTcxNDgzZGIwZjQ5Zjc5YThhM2FkYTkiLCJwIjoiaiJ9

    // fires off an analytics event to track feature usage statistics.
    analytics.clickTargetDrawerObservation({
        itemId: day.id,
        platform: day.platform,
        emissionRate: day.totalKgHr ?? NaN,
        label: `${endOfDay}`
    });
};
