import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { Box } from '../Box';
import Chip from '../Chip';
import { ChevronLeftIcon, ChevronRightIcon } from '../Icons';
import { Grid } from '../Layout';
import { scrollToNextElement, scrollToPreviousElement } from './scrollElements';
import { Theme } from '@mui/material';

interface HorizontalScrollProps {
    elements: ReactNode[];
    centerAlignArrows?: boolean;
    ariaLabelRightScroll?: string;
    ariaLabelLeftScroll?: string;
    scrollContainerRef: React.RefObject<HTMLDivElement>;
    // /** When set to true, this component must be loaded dynamically using next/dynamic, with {ssr: false}  */
    // enableResizeObserver?: boolean;
}

const DatePickerHorizontalScroll = ({
    ariaLabelRightScroll,
    ariaLabelLeftScroll,
    centerAlignArrows,
    elements,
    scrollContainerRef
}: HorizontalScrollProps) => {
    const lhScrollRef = useRef<HTMLDivElement>(null);
    const rhScrollRef = useRef<HTMLDivElement>(null);

    const [isAtLeftEnd, setIsAtLeftEnd] = useState(false);
    const [isAtRightEnd, setIsAtRightEnd] = useState(true);

    // Callback to onditionally disable and enable next and previous buttons
    // if we are at the end or beginning of the scrolled items.
    const scrollCallback = useCallback(
        (target: Element) => {
            if (target) {
                const { clientWidth, scrollLeft, scrollWidth } = /* target as typeof */ target;

                setIsAtLeftEnd(scrollLeft <= 5);

                // Certain screen sizes do not work with an equality check.
                // A range is given so that the user has a consistent experience regardless
                // of screen size.

                // scroll right = how much further could the item scroll to the right
                const scrollRight = Math.floor(scrollWidth - scrollLeft - clientWidth);
                setIsAtRightEnd(scrollRight <= 5);
            }
        },
        [scrollContainerRef.current]
    );

    const handleNextClick = () => {
        // scroll to next element
        scrollContainerRef.current && scrollToNextElement(scrollContainerRef.current);
        // trigger scroll callback, even if no scrolling happens
        if (scrollContainerRef.current) scrollCallback(scrollContainerRef.current);
        // blur right scroll button
        rhScrollRef.current?.blur();
    };

    const handlePreviousClick = () => {
        // scroll to previous element
        scrollContainerRef.current && scrollToPreviousElement(scrollContainerRef.current);
        // // trigger scroll callback, even if no scrolling happens
        if (scrollContainerRef.current) scrollCallback(scrollContainerRef.current);
        // blur left scroll button
        lhScrollRef.current?.blur();
    };

    // style for the left & right scrolling buttons
    const scrollChipStyle = (theme: Theme) => ({
        height: theme.mixins.chip?.height,
        borderColor: theme.palette.grey[700],
        borderStyle: 'solid',
        borderRadius: theme.spacing(3),
        borderWidth: '1px',
        // Make sure that the icon is in the middle of the chip
        '& .MuiChip-label': {
            paddingLeft: 0
        },
        '&.Mui-disabled': {
            pointerEvents: 'none !important',
            '&:hover, &:focus, &.Mui-focusVisible': { backgroundColor: theme.palette.background.paper }
        }
    });

    // Left hand scroller
    const LHScroll = (
        <Box alignItems={'center'} display={'flex'}>
            <Chip
                ref={lhScrollRef}
                color="secondary"
                data-testid="click-back"
                disabled={isAtLeftEnd}
                skipFocusWhenDisabled
                icon={<ChevronLeftIcon />}
                sx={scrollChipStyle}
                onClick={handlePreviousClick}
                aria-label={ariaLabelLeftScroll}
            />
        </Box>
    );

    // Right hand Scroller
    const RHScroll = (
        <Box display={'inline-flex'} alignItems={'center'} textAlign={'right'}>
            <Chip
                ref={rhScrollRef}
                color="secondary"
                data-testid="click-next"
                disabled={isAtRightEnd}
                skipFocusWhenDisabled
                icon={<ChevronRightIcon />}
                sx={scrollChipStyle}
                onClick={handleNextClick}
                aria-label={ariaLabelRightScroll}
            />
        </Box>
    );

    // The list of elements that scroll horizontally
    const scrollableElements = (
        <Grid
            sx={{
                overflowX: 'scroll',
                // When scrolling to the next item, we make scrolling
                // smooth instead of a jump cut
                scrollBehavior: 'smooth',
                // Used to hide the scrollbar on some browsers
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                // Used to hide the scrollbar on some browsers
                scrollbarWidth: 'none',
                maxWidth: 'inherit'
            }}
            wrap="nowrap"
            container
            // least hacky way to allow outlines on TimesliderDays
            padding={centerAlignArrows ? '4px' : undefined}
            ref={scrollContainerRef}
            className="msat_time-selector_horizontal-scroll__elements"
            role="listbox"
        >
            {elements.map((element, index) => {
                return (
                    <Grid
                        item
                        key={index}
                        xs="auto"
                        // removes extra padding on first element
                        paddingLeft={index === 0 ? 0 : 1}
                    >
                        {element}
                    </Grid>
                );
            })}
        </Grid>
    );

    return (
        <Grid
            container
            spacing={1}
            flexWrap="nowrap"
            width="unset"
            maxWidth="inherit"
            marginLeft={0}
            className="msat_time-selector_horizontal-scroll"
        >
            {/** Left Hand Side scroll arrow */}
            <Grid item xs="auto" alignContent={centerAlignArrows ? 'center' : undefined} marginLeft={-1}>
                {LHScroll}
            </Grid>
            {/** Array of scrollable elements */}
            <Grid
                item
                xs
                flexBasis={0}
                flexGrow={0}
                flexShrink={1}
                // 98px is a bit of a magic number: it's the width of the scroll buttons + their padding.
                // We could later write a mixin if that's determined to be preferable.
                width="calc(100% - 98px - 8px)"
                maxWidth="fit-content !important"
            >
                {scrollableElements}
            </Grid>
            {/** Right Hand Side scroll arrow */}
            <Grid item xs="auto" alignContent={centerAlignArrows ? 'center' : undefined}>
                {RHScroll}
            </Grid>
        </Grid>
    );
};

export default DatePickerHorizontalScroll;
