import { ReactNode } from 'react';
import { Box } from '../Box';
import Typography from '../Typography';

interface ZoomInfoBoxProps {
    children: ReactNode;
}

/**
 *  Zoom information that will render if a chip is disabled
 */
const ZoomInfoBox = ({ children }: ZoomInfoBoxProps) => {
    return (
        <Box
            sx={(theme) => ({
                marginX: '12px',
                marginTop: 1,
                marginBottom: 2,
                borderRadius: theme.shape.borderRadius,
                textAlign: 'center',
                zIndex: 11
            })}
        >
            <Typography variant="body2">{children}</Typography>
        </Box>
    );
};

export default ZoomInfoBox;
