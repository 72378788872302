import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    BaseMap,
    DeckGLRef,
    getBoundingBox,
    InitialMapViewState,
    Layer,
    PickingInfo,
    ViewStateChangeParameters
} from '@methanesat/maps';
import { Box } from '@methanesat/ui-components';
import { centroid, polygon } from '@turf/turf';

import { playwrightAddViweport } from '../../../test/e2e/utils';
import { getRasterColorMapName, GoogleBasemaps, maxZoomBaseMap, maxZoomOGITiles, wholeWorldBbox } from '../../consts';
import { ENVIRONMENT, GOOGLE_MAPS_API_KEY, MAPBOX_TOKEN } from '../../environmentVariables';
import { useGetCapturesWithinGlobalTimeRange, useGetLayerInfoFromUrl, useTranslate } from '../../hooks';
import {
    useAreaEmissionIcon,
    useInfrastructureTileLayer,
    useMethaneRasterLayers,
    usePlumeEmissionRateLayer,
    useSyncQueryString,
    useTargetLayers
} from '../../hooks/pages/emissions-map';
import {
    resetSelectedFeatureParams,
    selectEmissionsMapStyle,
    selectEmissionsMapStyleUrl,
    selectFlooredZoom,
    selectGlobalDate,
    selectMethaneProduct,
    selectPlatform,
    selectVisualizedFeatureCoordinates,
    selectVisualizedFeatureDate,
    selectVisualizedFeatureTargetId,
    selectVisualizedFeatureType,
    selectVisualizedStacCollectionId,
    selectVisualizedStacItemId,
    setEmissionsMapBBox,
    setEmissionsMapViewState,
    setSelectedFeatureParams,
    SLOW_selectEmissionsMapViewState
} from '../../reducers';
import { RootState } from '../../store';
import {
    isAreaEmissionInfo,
    isPlumeEmissionInfo,
    isTargetInfo,
    MSatPickInfo,
    PickInfoToPrioritize,
    UrlFeatureTypes,
    UrlStateItem,
    ViewStatePropsQueryString
} from '../../types';
import {
    formatDateForUrl,
    getCollectionName,
    pickMultiplePrioritizedFilterdLayers,
    pickValidTopPriorityInfo
} from '../../utils';
import { QueryStringObject } from '../../utils/urlValidators';
import { EmissionsPageMapScale } from '../EmissionsPageMapScale';

/**
 * Emissions maps props.
 */
export interface EmissionsPageMapProps {
    /** Width of the screen for calculating modal placement when left panel is open. */
    leftWidthOffset?: number;
    /** Called when the map was click with the layer that was clicked if there is one. */
    handleMapInfo?: (info: PickInfoToPrioritize | PickInfoToPrioritize[] | undefined, isNoData: boolean) => void;
    /** Width of the screen for calculating modal placement when right panel is open. */
    rightWidthOffset?: number;
}

const PICKING_RADIUS_PIXELS = 10;

/**
 * Emissions map gives context to where methane plumes are, who's emitting them, and how much is being emitted.
 */
function EmissionsPageMap({ handleMapInfo, rightWidthOffset = 0 }: EmissionsPageMapProps): ReactElement {
    const deck = useRef<DeckGLRef | null>(null);
    const dispatch = useDispatch();
    const t = useTranslate();

    //platform
    const platform = useSelector(selectPlatform);

    // date selected
    const globalDate = useSelector(selectGlobalDate);
    const globalDateObject = useMemo(() => {
        return new Date(globalDate);
    }, [globalDate]);

    // viewState
    const viewState = useSelector(SLOW_selectEmissionsMapViewState);
    const flooredZoom = useSelector(selectFlooredZoom);

    const mapStyleUrl = useSelector(selectEmissionsMapStyleUrl);
    const mapStyle = useSelector(selectEmissionsMapStyle);
    const gestureHandling = useSelector((state: RootState) => state.pages.emissions.mapInterface.gestureHandling);

    // variables identifying the selected feature (only methane for now)
    // used to update the url
    const selectedStacCollectionId = useSelector(selectVisualizedStacCollectionId);
    const selectedStacItemId = useSelector(selectVisualizedStacItemId);
    const selectedFeatureType = useSelector(selectVisualizedFeatureType);
    const selectedFeatureDate = useSelector(selectVisualizedFeatureDate);
    const selectedFeatureTargetId = useSelector(selectVisualizedFeatureTargetId);
    const [selectedFeatureLng, selectedFeatureLat] = useSelector(selectVisualizedFeatureCoordinates);

    const layerInfo = useGetLayerInfoFromUrl();

    // Listens for changes to `layerInfo` which is set after querying for a feature from the URL parameters.
    // If `layerInfo` isn't null, then it calls `handleMapInfo` to open the drawer and add the map highlights.
    useEffect(() => {
        if (layerInfo) {
            handleMapInfo?.(layerInfo, false);
        }
    }, [layerInfo]);

    // if the data is L3 or L4
    const product = useSelector(selectMethaneProduct);
    const featureList = useGetCapturesWithinGlobalTimeRange();

    const rasterColorMapName = getRasterColorMapName(platform);

    // methane layers
    // l4 layers
    const methaneRasterLayers = useMethaneRasterLayers(rasterColorMapName, featureList);
    const plumeEmissionRateLayer = usePlumeEmissionRateLayer(featureList);

    // target layer
    const targetLayer = useTargetLayers(viewState.zoom, featureList);

    // selected area emissions layers
    const selectedAreaEmissionsLayer = useAreaEmissionIcon();

    const tileInfrastructureLayer = useInfrastructureTileLayer();

    // array of deck.gl layers passed to map
    // These are ordered by the order in which they should be stacked by deck.gl:
    // layers early in the array are stacked below those later in the array
    const layers: Layer[] = [
        targetLayer,
        ...methaneRasterLayers,
        plumeEmissionRateLayer,
        selectedAreaEmissionsLayer,
        tileInfrastructureLayer
    ].filter((a) => !!a) as Layer[];

    // function to update the URL no more than once every 500 ms
    // all state-related URL updates are throttled, not just the ones that *must* be throttled
    // because there is no down-side to throttling all updates
    const debouncedRouterReplace = useCallback(
        debounce(
            (newQuery: QueryStringObject) => {
                const searchParams = new URLSearchParams();
                // Add or update parameters based on the new state
                Object.entries(newQuery).forEach(([key, value]) => {
                    if (value !== '') {
                        searchParams.set(key, value);
                    } else {
                        searchParams.delete(key);
                    }
                });

                const querystr = searchParams.toString();
                history.replaceState({ ...history.state }, '', `?${querystr}`);
            },
            500,
            {
                trailing: true
            }
        ),
        []
    );

    const getTooltip = useCallback((info: PickingInfo) => {
        const infoLayer = info.layer;
        if (!infoLayer) return null;
        if (!info.object) return null;

        if (
            (infoLayer.id === 'targets' || infoLayer.id === 'targetsmultiple-capture-icon') &&
            info.object.properties.monthlyCaptureCount > 1
        ) {
            return (
                info.object && {
                    text: t('targetTooltip', { monthlyCaptureCount: info.object.properties.monthlyCaptureCount }),
                    style: {
                        position: 'absolute',
                        top: '-50px',
                        left: '-35px',
                        borderRadius: '2px',
                        color: '#ffffff',
                        fontSize: '16px'
                    }
                }
            );
        }
    }, []);

    const dateString = useMemo(() => {
        return formatDateForUrl(globalDateObject);
    }, [globalDate]);

    const { latitude, longitude, zoom } = viewState;
    const cleanViewState = { latitude, longitude, zoom: zoom.toFixed(2) };

    // List defining the state useSyncQueryString should add to & extract from the URL's querystring
    // Also defines how to handle each querystring parameter's value on page load
    const urlStateItems: UrlStateItem[] = useMemo(() => {
        return [
            {
                queryKey: 'view',
                value: cleanViewState
            },
            {
                queryKey: 'date',
                value: dateString
            },
            {
                queryKey: 'platform',
                value: platform
            },
            {
                queryKey: 'collection-id',
                value: selectedStacCollectionId
            },
            {
                queryKey: 'item-id',
                value: selectedStacItemId
            },
            {
                queryKey: 'feature-type',
                value: selectedFeatureType
            },
            {
                queryKey: 'feature-date',
                value: selectedFeatureDate
            },
            {
                queryKey: 'feature-target',
                value: selectedFeatureTargetId
            },
            {
                queryKey: 'feature-lat',
                value: selectedFeatureLat
            },
            {
                queryKey: 'feature-lng',
                value: selectedFeatureLng
            }
        ];
    }, [
        mapStyle,
        viewState,
        dateString,
        platform,
        selectedStacCollectionId,
        selectedStacItemId,
        selectedFeatureType,
        selectedFeatureDate,
        selectedFeatureLng,
        selectedFeatureLng
    ]);

    useSyncQueryString({
        urlStateItems,
        dispatch,
        urlUpdateFn: debouncedRouterReplace
    });

    const throttledUpdateBBox = useMemo(() => {
        return throttle(
            function (viewState: InitialMapViewState) {
                const bbox = getBoundingBox(viewState);
                dispatch(setEmissionsMapBBox(bbox));
            },
            500,
            { leading: false, trailing: true }
        );
    }, []);

    async function onMapClick(info: MSatPickInfo) {
        if (!deck?.current) return;

        if (selectedStacCollectionId || selectedStacItemId || selectedFeatureType) {
            dispatch(resetSelectedFeatureParams());
        }

        // get pickinfo from all layers with features
        // at the coordinates the user clicked
        const layersOfInfoAtPoint: PickInfoToPrioritize[] = deck.current.pickMultipleObjects({
            x: info.x,
            y: info.y,
            depth: layers.length,
            radius: PICKING_RADIUS_PIXELS
        }) as PickInfoToPrioritize[];

        // keep infos with valid objects and bitmaps
        const filteredLayersOfInfoAtPoint = layersOfInfoAtPoint.filter((info) => {
            return (typeof info.object === 'object' && info.object) || info.bitmap;
        });

        /** get top priority layer */
        const prioritizedInfo = await pickValidTopPriorityInfo(
            filteredLayersOfInfoAtPoint,
            globalDateObject,
            product,
            platform
        );

        /**
         * All infos will be features from the same deck.gl layer. Thus, we can show data for multiple
         * features in the OGI layer, or the plumes layer, etc.
         * This will NOT show data for multiple features in different layers in the same drawer.
         */
        const multiplePrioritizedInfos = pickMultiplePrioritizedFilterdLayers(
            filteredLayersOfInfoAtPoint,
            prioritizedInfo?.layer.id
        );

        /**
         * If user clicked a negative emission,
         * they clicked a layer, but got no prioritized info to show
         */
        const clickedNoData = !prioritizedInfo;

        // Reposition the map if what you clicked will be behind a panel
        const deckElementRect =
            !deck.current.deck || !deck.current.deck['canvas']
                ? null
                : deck.current.deck['canvas'].getBoundingClientRect();
        const deckWidth = deckElementRect?.width;
        const repositionMap =
            deckWidth && // have a deck element to work with
            !clickedNoData && // clicked empty data/no layers
            (prioritizedInfo?.x ?? 0) > deckWidth + rightWidthOffset; // feature is hidden behind the drawer

        // call handleMapInfo - opens the info drawer - with the info that was clicked.
        if (
            !clickedNoData &&
            flooredZoom >= maxZoomOGITiles - 1 &&
            multiplePrioritizedInfos?.length &&
            multiplePrioritizedInfos?.length > 1
        ) {
            handleMapInfo?.(multiplePrioritizedInfos, clickedNoData);
        } else if (prioritizedInfo) {
            handleMapInfo?.(prioritizedInfo, clickedNoData);
        } else {
            handleMapInfo?.(undefined, clickedNoData);
        }

        if (
            prioritizedInfo &&
            (isAreaEmissionInfo(prioritizedInfo) ||
                isPlumeEmissionInfo(prioritizedInfo) ||
                isTargetInfo(prioritizedInfo))
        ) {
            let featureType;
            let featureCoordinates: GeoJSON.Position;
            let featureDate: string;
            let featureItemId: string;
            let featureTargetId: string;

            if (isAreaEmissionInfo(prioritizedInfo)) {
                featureType = UrlFeatureTypes.AreaEmission;
                featureCoordinates = prioritizedInfo.coordinate;
                featureDate = prioritizedInfo.object.properties?.collectionStartTime;
                featureItemId = prioritizedInfo.layer.itemId;
                featureTargetId = prioritizedInfo.layer.targetId;
            } else if (isPlumeEmissionInfo(prioritizedInfo)) {
                featureType = UrlFeatureTypes.Plume;
                featureCoordinates = prioritizedInfo.object.geometry.coordinates;
                featureDate = prioritizedInfo.object.properties?.start_datetime;
                featureItemId = prioritizedInfo.object.itemId;
                featureTargetId = prioritizedInfo.object.targetId;
            } else if (isTargetInfo(prioritizedInfo)) {
                featureType = UrlFeatureTypes.Target;
                featureCoordinates = centroid(polygon(prioritizedInfo.object.geometry.coordinates)).geometry
                    .coordinates;
                featureDate = prioritizedInfo.object.properties?.start_datetime;
                featureItemId = prioritizedInfo.object.id;
                featureTargetId = prioritizedInfo.object.properties.target_id;
            } else {
                // should never reach this, but TS requires an `else` statement
                throw new Error(`Unsupported map info`);
            }
            const featureUrlDateString = formatDateForUrl(new Date(featureDate));

            dispatch(
                setSelectedFeatureParams({
                    coordinates: featureCoordinates,
                    targetDate: featureUrlDateString,
                    targetId: featureTargetId,
                    featureType: featureType,
                    collectionId: getCollectionName(product, platform),
                    itemId: featureItemId
                })
            );
        }

        if (repositionMap) {
            // reposition longitudinally only
            const recenteredViewState: ViewStatePropsQueryString = {
                ...viewState,
                longitude: prioritizedInfo?.coordinate[0] || viewState.longitude
            };
            dispatch(setEmissionsMapViewState(recenteredViewState as ViewStatePropsQueryString));
        }
    }

    // handler for viewStateChange event on Deck
    function onViewStateChange({ viewState }: ViewStateChangeParameters) {
        // this allows us to exclude non-serializable properties
        // of viewState from the store (ex. transitionEasing )
        const { latitude, longitude, zoom, bearing, pitch, altitude, height, width } = viewState;

        // We remove any undefineds so the Google base map is able to send a partial
        // viewState update without affecting other values
        const newViewState = Object.entries({
            latitude,
            longitude,
            zoom,
            bearing,
            pitch,
            altitude,
            height,
            width
        }).reduce((createdViewState, [key, value]) => {
            if (value !== undefined) {
                return { ...createdViewState, [key]: value };
            }
            return createdViewState;
        }, {});
        dispatch(setEmissionsMapViewState(newViewState as ViewStatePropsQueryString));

        // adding Viewport to window for playwright e2e tests
        if (ENVIRONMENT !== 'production') {
            playwrightAddViweport(viewState);
        }

        throttledUpdateBBox(viewState as InitialMapViewState);
    }

    const [west, south, east, north] = wholeWorldBbox;
    const latLngBounds = { west, south, east, north };
    return (
        <>
            {GOOGLE_MAPS_API_KEY ? (
                /**
                 * Adding a grayscale filter to images in the basemap makes the
                 * Google base map gray without changing the colors of the
                 * deck.gl layers. The filter values were arrived at by testing
                 * various combinations with Product.
                 */
                <Box
                    id="emissions-map-google-container"
                    data-testid="emissions-map-google-container"
                    component="span"
                    sx={{
                        '& img': {
                            filter: 'grayscale(0.97) brightness(1.13) contrast(1.14) opacity(0.96) saturate(4.26)'
                        }
                    }}
                >
                    <BaseMap
                        id="emissions-map-google"
                        data-testid="emissions-map-google"
                        gestureHandling={gestureHandling}
                        googleMapsAPIKey={GOOGLE_MAPS_API_KEY}
                        viewState={{
                            ...viewState
                        }}
                        onViewStateChange={onViewStateChange}
                        layers={layers}
                        onClick={(info) => onMapClick(info as MSatPickInfo)}
                        ref={deck}
                        restriction={{
                            latLngBounds,
                            strictBounds: false
                        }}
                        maxZoom={maxZoomBaseMap}
                        getTooltip={getTooltip}
                        mapId={GoogleBasemaps.roadmapLight.id}
                    />
                </Box>
            ) : (
                <Box data-testid="emissions-map-deck-container">
                    <BaseMap
                        data-testid="emissions-map-deck"
                        viewState={{
                            ...viewState,
                            maxZoom: maxZoomBaseMap
                        }}
                        onViewStateChange={onViewStateChange}
                        getTooltip={getTooltip}
                        layers={layers}
                        mapboxApiAccessToken={MAPBOX_TOKEN}
                        mapStyle={mapStyleUrl}
                        onClick={(info) => onMapClick(info as MSatPickInfo)}
                        pickingRadiusPixels={PICKING_RADIUS_PIXELS}
                        ref={deck}
                        Scale={<EmissionsPageMapScale />}
                    />
                </Box>
            )}
        </>
    );
}

export default EmissionsPageMap;
