import { Paper, Box, TimeSelectorProps } from '@methanesat/ui-components';
import { TargetSummaryLayout } from '../TargetSummaryLayout';

export const FloatingTargetSummary = ({
    data,
    emissions,
    emissionsTitle,
    overTimeTitle,
    title
}: {
    data: TimeSelectorProps['data'];
    emissions: string;
    emissionsTitle: string;
    overTimeTitle: string;
    title: string;
}) => {
    return (
        <Box
            data-testid="floating-target-summary"
            position="absolute"
            bottom={0}
            right={0}
            sx={(theme) => ({
                marginBottom: theme.spacing(4),
                marginX: theme.spacing(2),
                '@keyframes fadeIn': {
                    from: { opacity: 0 },
                    to: { opacity: 1 }
                },
                maxWidth: {
                    // the amount subtracted should be double the marginX value
                    xs: `calc(100vw - ${theme.spacing(4)})`,
                    sm: '500px'
                },
                animation: 'fadeIn ease-in 0.25s'
            })}
        >
            <Paper
                sx={{
                    padding: 4,
                    maxWidth: 'inherit'
                }}
            >
                <TargetSummaryLayout
                    data={data}
                    emissions={emissions}
                    emissionsTitle={emissionsTitle}
                    title={title}
                    overTimeTitle={overTimeTitle}
                />
            </Paper>
        </Box>
    );
};
