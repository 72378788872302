import React, { ReactNode, useRef } from 'react';

import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '../Accordion';
import { Box } from '../Box';
import { ArrowDropDownIcon } from '../Icons';
import { FormGroup } from '@mui/material';

export interface ChipSelectProps extends Required<Pick<AccordionProps, 'expanded' | 'onChange'>> {
    'data-testid': string;
    expandIconId?: string;
    disabled: boolean;
    label: ReactNode;
    children: ReactNode | ReactNode[];
}
/**
 * An accordion that looks like a chip for the purpose of holding form elements that
 * the user can interact with. For example, this chip can be given a list of checkboxes
 * that the user can click.
 */
const ChipSelect = ({
    children,
    'data-testid': dataTestId,
    expanded,
    expandIconId,
    label,
    disabled,
    onChange
}: ChipSelectProps) => {
    const summaryRef = useRef<HTMLDivElement>(null);

    // maxWidth that doesn't look to odd on load
    const maxChipWidth = 180;

    return (
        <Accordion
            data-testid={dataTestId}
            elevation={0}
            expanded={expanded}
            onChange={onChange}
            disabled={disabled}
            sx={(theme) => ({
                margin: 0,
                zIndex: 11,
                width: 'fit-content',
                borderColor: theme.palette.grey[700],
                borderRadius: theme.mixins.chip?.borderRadius,
                borderStyle: 'solid',
                borderWidth: '1px',
                '&:last-of-type': { borderRadius: theme.mixins.chip?.borderRadius },
                '&.Mui-expanded': {
                    margin: 0,
                    overflowY: 'auto',
                    maxHeight: '85vh'
                },
                '&.Mui-disabled': {
                    backgroundColor: theme.palette.disabledGray.dark
                }
            })}
        >
            <AccordionSummary
                ref={summaryRef}
                sx={(theme) => ({
                    minHeight: theme.mixins.chip?.height,
                    height: theme.mixins.chip?.height,
                    padding: '0 6px',
                    alignContents: 'center',
                    backgroundColor: theme.palette.secondary.main,
                    borderWidth: '0',
                    borderRadius: theme.mixins.chip?.borderRadius,
                    color: theme.palette.secondary.contrastText,
                    display: 'in-line',
                    justifyContent: 'right',
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                        color: theme.palette.getContrastText(theme.palette.action.hover)
                    },
                    '&.Mui-expanded': {
                        minHeight: 0
                    },
                    '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
                        margin: '0px 0'
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        color: 'inherit'
                    },
                    '&.Mui-disabled': {
                        cursor: 'pointer'
                    }
                })}
                expandIcon={<ArrowDropDownIcon id={expandIconId} />}
            >
                {label}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    paddingX: 0,
                    paddingBottom: 1,
                    width: '100%',
                    position: 'relative'
                }}
                data-testid={`${dataTestId}-details`}
            >
                {/** Adding greyed out overlay when accordion is disabled */}
                {disabled && (
                    <Box
                        sx={(theme) => ({
                            backgroundColor: theme.palette.disabledGray.dark,
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            zIndex: 10,
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            opacity: 0.5
                        })}
                    />
                )}
                {/* FormGroup aligns the elements vertically */}
                <FormGroup sx={{ marginX: '12px', width: `${maxChipWidth}px` }}>
                    {children && [...(Array.isArray(children) ? children : [children])]}
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    );
};

export default ChipSelect;
