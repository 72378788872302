import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { log } from '@methanesat/log';
import { Box, Grid, LocaleDateVariant, PlumeIcon, Typography, useTheme } from '@methanesat/ui-components';

import { METHANESAT_FAQ_URL } from '../../../../../consts';
import { SHOW_OGI_NEAR_PLUMES, SHOW_SHAREABLE_FEATURE_LINK } from '../../../../../environmentVariables';
import { useGetHistoricalSamplesForTarget, usePlumeEmissionsUnitsLabel, useTranslate } from '../../../../../hooks';
import {
    resetOgiNearPlumes,
    selectEmissionsMapFilters,
    selectPlatform,
    selectTargetDates
} from '../../../../../reducers';
import { RootState } from '../../../../../store';
import { MethaneLayerIds, PlumeDrawerProps, STACCalendarData, UrlFeatureTypes } from '../../../../../types';
import {
    analytics,
    formatCoordinates,
    formatMeasurementTime,
    getColorRangeColors,
    getFeatureLinkLabel
} from '../../../../../utils';
import { DrawerHeader } from '../../Headers';
import { DataRowsModalBody, FeatureLink } from '../../Layout';
import { OGINearEmissions } from '../OGINearEmissions';
import { EmissionsHeadline } from './EmissionsHeadline';
import { MoreInfoSection } from './MoreInfoSection';
import { handleTargetDrawerDayClick, stacDatesToTimeSelector } from './helpers';
import dynamic from 'next/dynamic';

/**
 * next/dynamic here allows us to use enableResizeObserver within the HorizontalScroll component.
 * enableResizeObserver helps with right scrolling issues within the drawer.
 */
const DynamicTimeSelector = dynamic(() => import('@methanesat/ui-components').then((module) => module.TimeSelector), {
    ssr: false,
    loading: () => <p>Loading...</p>
});

function useGetPlumeDetailRows(info: PlumeDrawerProps['info']) {
    const t = useTranslate();
    const timeLabel = t('emissionsMapPage.mapModals.emissions.measurementTimeLabel');
    const coordinatesLabel = t('emissionsMapPage.mapModals.common.dataTable.coordinatesLabel');
    const locationLabel = t('emissionsMapPage.mapModals.common.dataTable.locationLabel');
    return [
        {
            label: locationLabel,
            value: info.object.properties.location
        },
        ...(info.object.geometry.coordinates
            ? [
                  {
                      label: coordinatesLabel,
                      value: formatCoordinates(info.object.geometry.coordinates)
                  }
              ]
            : []),
        {
            label: timeLabel,
            value: formatMeasurementTime(info.object.properties.start_datetime, info.object.properties.end_datetime)
        }
    ];
}

// TODO:  DP-1892 MethaneRasterDataDrawer and PlumeEmissionsDrawer are nearly identical and can
// likely be refactored to consolidate code

/**
 * Distinct point source drawer.
 * Warning: info.coordinate is the coordinate of where the user clicked to activate the drawer, NOT the coordinate of
 * the underlying data.
 */
export const PlumeEmissionsDrawer = ({ info }: PlumeDrawerProps) => {
    const dispatch = useDispatch();
    const t = useTranslate();
    const theme = useTheme();

    const [longitude, latitude] = info.object.geometry.coordinates;

    const plumeFlux = info.object.properties.flux;
    const methaneLowerBounds = info.object.properties.flux_lo;
    const methaneUpperBounds = info.object.properties.flux_hi;
    const collectionStartTime = info.object.properties.start_datetime;
    const location = info.object.properties.location;
    const itemId = info.object.itemId;
    const targetId = info.object.targetId;
    const collection = info.object.collection;

    const selectorDates = useGetHistoricalSamplesForTarget(targetId) ?? [];

    const platform = useSelector(selectPlatform);
    const targetDates = useSelector(selectTargetDates);
    const label = collection && itemId && getFeatureLinkLabel(platform, itemId, location);

    // Based on whether we are filtering by ogim data, we want to show the details
    const showOgiDetails = useSelector((state: RootState) => selectEmissionsMapFilters(state).ogimFeatures.length > 0);

    useEffect(() => {
        if (plumeFlux !== undefined && plumeFlux >= 0) {
            analytics.openPlumeEmissionDetails({
                id: `${info.object.id}`,
                emissionRate: plumeFlux,
                latitude,
                longitude
            });
        }
    }, [info.object.id, latitude, longitude, plumeFlux]);

    // Closes the drawer and undoes the ogiNearPlumes action
    const resetFixedState = () => {
        dispatch(resetOgiNearPlumes());
    };

    const drawerTitle = showOgiDetails
        ? t('emissionsMapPage.mapModals.emissions.nearbyInfrastructureTitle')
        : t('emissionsMapPage.mapModals.emissions.plumeTitle');

    const detailDataRows = useGetPlumeDetailRows(info);

    // Verify plumeFlux was set,
    // NOTE: this is intentionally called after all the hooks are created
    if (plumeFlux === undefined || plumeFlux < 0) {
        log.error('Plume data does not contain valid methane value: flux undefined on plume');
        throw Error('Plume data does not contain valid methane value');
        //TODO: Ticket to analyse analytics and recovery after an error state
    }

    const colorRangeIconColors = getColorRangeColors(theme, true);

    // This is a blank string in the locales file. If/when we know what
    // the text should be, it will be very easy to update there.
    const descriptionText = t(`emissionsMapPage.mapModals.emissions.pointDescription`);
    const plumeEmissionsUnitsLabel = usePlumeEmissionsUnitsLabel();

    return (
        <>
            <Box>
                <DrawerHeader
                    title={drawerTitle}
                    icon={showOgiDetails ? null : <PlumeIcon colors={colorRangeIconColors} sx={{ marginTop: 1 }} />}
                />
                {/* Emissions & confidence */}
                <Grid container marginTop={5} marginBottom={3} rowGap={2}>
                    <Grid item xs={12}>
                        <EmissionsHeadline
                            data-testid="plume-emissionsheadline-value"
                            emissions={plumeFlux}
                            lowEmissions={methaneLowerBounds}
                            highEmissions={methaneUpperBounds}
                            units={plumeEmissionsUnitsLabel}
                        />
                    </Grid>
                    {descriptionText && (
                        <Grid item xs={12}>
                            <Typography variant="body1">{descriptionText}</Typography>
                        </Grid>
                    )}
                </Grid>

                {!showOgiDetails && (
                    <>
                        {/* At a glance */}
                        <Grid container paddingTop={3} paddingBottom={1}>
                            <Typography variant="h4">
                                {t('emissionsMapPage.mapModals.common.dataTableTitle')}
                            </Typography>
                        </Grid>
                        <DataRowsModalBody dataRows={detailDataRows} />

                        {/* Observations over time */}
                        <Grid container marginTop={3} marginBottom={3} rowGap={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4">
                                    {t('emissionsMapPage.mapModals.common.overTimeTitle')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <DynamicTimeSelector
                                    data-testid="time-selector-day-picker"
                                    data={stacDatesToTimeSelector(
                                        selectorDates,
                                        collectionStartTime,
                                        (day: STACCalendarData) => {
                                            handleTargetDrawerDayClick({ day, dispatch, targetId });
                                        }
                                    )}
                                    enableResizeObserver
                                    variant={LocaleDateVariant.day}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}

                {/* OGI */}
                {SHOW_OGI_NEAR_PLUMES && (
                    <OGINearEmissions info={info} showDetails={showOgiDetails} resetMap={resetFixedState} />
                )}

                {/** Info Links */}
                {!showOgiDetails && (
                    <>
                        <MoreInfoSection
                            header={t('emissionsMapPage.mapModals.plumes.moreInfo.header')}
                            content={t('emissionsMapPage.mapModals.plumes.moreInfo.content', {
                                link: METHANESAT_FAQ_URL
                            })}
                        />
                        {/* Shareable link to feature */}
                        {SHOW_SHAREABLE_FEATURE_LINK && label && (
                            <Grid container marginTop={3}>
                                {/* title */}
                                <Grid item xs={12} marginBottom={2}>
                                    <Typography variant="h5">
                                        {t(
                                            `emissionsMapPage.mapModals.linksToFeatures.title.${MethaneLayerIds.plumeEmissionRate}`
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FeatureLink
                                        collectionId={collection}
                                        coordinates={[longitude, latitude]}
                                        date={collectionStartTime}
                                        featureLat={latitude}
                                        featureLng={longitude}
                                        featureType={UrlFeatureTypes.Plume}
                                        itemId={itemId}
                                        label={label}
                                        layerId={MethaneLayerIds.plumeEmissionRate}
                                        platform={platform}
                                        targetDate={targetDates[targetId]}
                                        targetId={targetId}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
            </Box>
        </>
    );
};
