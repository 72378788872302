import React, { useRef } from 'react';

import { TimeSelectorProps } from '../../types';
import { Box } from '../Box';
import TimeSelectorHorizontalScroll from '../HorizontalScroll/TimeSelectorHorizontalScroll';
import LocaleDate from '../LocaleDate';
import Typography from '../Typography';
import { TimeSelectorDay } from './TimeSelectorDay';

/**
 *
 * Takes in date/time values to render a list of elements that will update map data when clicked
 *
 */
export const TimeSelector = ({
    centerLabel,
    data,
    'data-testid': dataTestId,
    platform,
    variant,
    layout = 'vertical'
}: TimeSelectorProps) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    return (
        <Box
            key={platform}
            display="flex"
            data-testid={dataTestId}
            width="fit-content"
            maxWidth={layout === 'vertical' ? 'inherit' : '100%'}
            className="msat_time-selector"
        >
            <TimeSelectorHorizontalScroll
                scrollContainerRef={scrollContainerRef}
                centerAlignArrows
                elements={data.map((item) => {
                    return (
                        <Box
                            id={item.id}
                            key={item.dateKey}
                            width="60px"
                            textAlign="left"
                            gap={1}
                            display="flex"
                            flexDirection="column"
                            onClick={!item.disabled ? item.onClick : undefined}
                            marginRight={0.5}
                            role="option"
                            aria-selected={item.selected}
                        >
                            <TimeSelectorDay
                                height={item.boxHeight}
                                color={item.color}
                                selected={item.selected}
                                date={centerLabel ? <LocaleDate date={item.value} variant={variant} /> : undefined}
                                disabled={item.disabled}
                                scrollContainerRef={scrollContainerRef}
                            />
                            {!centerLabel && (
                                <Typography
                                    variant="body2"
                                    fontWeight={item.selected ? 700 : 400}
                                    lineHeight={1.2}
                                    textAlign="center"
                                >
                                    <LocaleDate date={item.value} variant={variant} />
                                </Typography>
                            )}
                        </Box>
                    );
                })}
            ></TimeSelectorHorizontalScroll>
        </Box>
    );
};
